import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";
import logo from "../photos/logo1.png";
import mlogo from "../photos/mlogo1.jpg";
import logoMobile from "../photos/deva-03.svg";
import logoBottom from "../photos/logoBottom.svg";
import CloseIcon from "@mui/icons-material/Close";
import { motion, useScroll } from "framer-motion";

const pagesLeft = [
  { text: "Home", to: "/" },
  { text: "Services", to: "#" },
];
const pagesRight = [
  { text: "About", to: "/about" },
  { text: "Contact Us", to: "/appointment" },
];

function Header1(props) {
  const { scrollYProgress } = useScroll();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const scroll = () => {
    const section = document.querySelector("#skin");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleCloseNavMenu = () => {
    scroll();
    setAnchorElNav(null);
  };
  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    setScrollProgress(scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress, height: "5px", width: "100%" }}
      />
      <AppBar
        position={scrollProgress > 1 ? "fixed" : "sticky"}
        transition="all 0.5s"
        sx={{
          bgcolor: "#374549",
        }}
      >
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: 1 } }}>
          <Toolbar
            sx={{ justifyContent: { md: "space-between", xs: "space-evenly" } }}
          >
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <Link to="/">
                <img
                  width={39}
                  height={51}
                  src={mlogo}
                  alt="Dune Aesthetics - Best Skin Clinic"
                ></img>
              </Link>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
              <Link to="/">
                <img
                  width={150}
                  height={60}
                  src={logoMobile}
                  alt="Dune Aesthetics - Best Skin Clinic"
                ></img>
              </Link>
            </Box>

            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                width: "15%",
                color: "white",
              }}
            >
              <a
                href="https://www.instagram.com/duneaesthetics?igshid=MzMyNGUyNmU2YQ=="
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <InstagramIcon
                  sx={{
                    display: { xs: "none", md: "block" },
                    color: "inherit",
                    mx: "7px",
                    fontSize: "18px",
                  }}
                />
              </a>
              <a
                href="#"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <FacebookIcon
                  sx={{
                    display: { xs: "none", md: "block" },
                    color: "inherit",
                    mx: "5px",
                    fontSize: "18px",
                  }}
                />
              </a>
              {/* <a
                href="https://google.com"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                <TwitterIcon
                  sx={{
                    display: { xs: "none", md: "block" },
                    color: "inherit",
                    mx: "7px",
                    fontSize: "18px",
                  }}
                />
              </a> */}
            </Box>
            <Box
              sx={{
                display: { md: "flex", xs: "none" },
                alignItems: "center",
                width: "80%",
                justifyContent: "center",
                ml: 4,
              }}
            >
              <Box
                sx={{
                  flexGrow: 0,
                  display: { xs: "none", md: "flex", justifyContent: "center" },
                }}
              >
                {pagesLeft.map((page, index) => (
                  <Button
                    component={Link}
                    to={page.to}
                    key={page}
                    onClick={index == 1 ? handleCloseNavMenu : null}
                    sx={{
                      color: "white",
                      display: "block",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      letterSpacing: 1,
                      fontSize: "16px",
                      px: 3,
                    }}
                  >
                    {page.text}
                  </Button>
                ))}
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  px: 5,
                  color: "#7A9CA5",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "140px",
                }}
              >
                <Link to="/">
                  <img src={logo} width={60} height={75} alt="wow" />
                </Link>
                <Box
                  sx={{
                    height: scrollProgress > 1 ? "0px" : "50px",
                    width: scrollProgress > 1 ? "0px" : "200px",
                    transition: "all 0.5s",
                  }}
                >
                  <img
                    src={logoBottom}
                    alt="Dune aesthetics By Dr. Aishwarya Bhatia"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  display: {
                    xs: "none",
                    md: "flex",
                    justifyContent: "flex-start",
                  },
                }}
              >
                {pagesRight.map((page, index) => (
                  <Button
                    component={Link}
                    to={page.to}
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{
                      color: "white",
                      display: "block",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      letterSpacing: 1,
                      fontSize: "16px",
                      px: 3,
                    }}
                  >
                    {page.text}
                  </Button>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none", justifyContent: "flex-end" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={props.handleDrawerToggle}
                color="black"
                sx={{
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                {props.mobileOpen ? (
                  <CloseIcon sx={{ color: "white" }} />
                ) : (
                  <MenuIcon sx={{ color: "white" }} />
                )}
              </IconButton>
            </Box>
            <Box sx={{ width: "15%", display: { xs: "none", md: "flex" } }}>
              <Button
                fullWidth
                component={Link}
                to="/appointment"
                variant="outlined"
                sx={{
                  color: "black",
                  backgroundColor: "#EBF3F5",
                  border: "none",
                  fontSize: { xs: 11, md: 13 },
                  "&:hover": {
                    backgroundColor: "#cce0e5",
                    color: "black",
                    border: "none",
                  },
                }}
              >
                Book Appointment
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header1;
