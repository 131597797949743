import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import clinic2 from "../photos/clinic2.jpeg";
import clinic1 from "../photos/clinic1.jpg";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            height: { md: "500px", xs: "200px" },
            background: `url(${clinic1}) left center no-repeat`,
            backgroundSize: "cover",
          }}
        ></Box>
        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            height: { md: "500px", xs: "200px" },
            background: `url(${clinic2}) left center no-repeat`,
            backgroundSize: "cover",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            width: { md: "500px", xs: "90%" },
            top: { md: "50%", xs: "70%" },
            left: "50%",
            boxSizing: "border-box",
            color: "#fff",
            border: "1px solid black",
            transform: "translate(-50%,-50%)",
            backgroundColor: "rgba(5,6,10,.7)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "start",
            fontFamily: "'Didact Gothic',sans-serif !important",
            gap: "20px",
            p: 2,
          }}
        >
          <Typography
            sx={{ color: "#fff", fontSize: { md: "24px", xs: "18px" } }}
          >
            ABOUT US
          </Typography>
          <Divider
            sx={{
              width: "20%",
              border: "1px solid #fff",
            }}
          />
          <Typography
            sx={{ color: "#fff", fontSize: { md: "22px", xs: "17px" } }}
          >
            Our clinic provides cutting-edge skincare procedures, such as
            chemical peels, microdermabrasion, dermal fillers, medical facials,
            micro-needling, laser hair reduction, and more, using the most
            advanced technologies and techniques to ensure optimal results. Our
            team, led by Dr Aishwarya Bhatia, is dedicated to collaborating with
            you in developing a customized treatment plan tailored to your
            specific needs and objectives.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 8,
        }}
      >
        <Box
          sx={{
            width: { md: "55%", xs: "90%" },
            display: "flex",
            flexDirection: "column",
            gap: "70px",
            mt: { xs: "100px" },
          }}
        >
          <Typography sx={{ fontSize: { md: "22px", xs: "18px" } }}>
            Dune Aesthetics Clinic, headed by Dr Aishwarya Bhatia, welcomes you!
            As a skilled and reliable Skin Doctor with more than five years of
            experience, Dr Aishwarya Bhatia is committed to assisting you in
            attaining glowing and healthy skin.
            <br />
            <br />
            Dr Bhatia and her team are committed to providing personalized
            attention and care to each client's unique needs, ensuring they feel
            comfortable and at ease during their treatments.
            <br />
            <br />
            We are at{" "}
            <b style={{ fontWeight: "500" }}>
              1/3, Basement, Old Double Storey, Vikram Vihar, Lajpat Nagar
              IV,Delhi.{" "}
            </b>
            Book an appointment with us today and get radiant, youthful-looking
            skin you deserve!"
          </Typography>
          <Button
            component={Link}
            to="/appointment"
            variant="outlined"
            sx={{
              color: "white",
              width: { md: "50%", xs: "90%" },
              margin: "0 auto",
              backgroundColor: "#374549",
              border: "none",
              fontSize: { xs: 14, md: 18 },
              "&:hover": {
                backgroundColor: "#cce0e5",
                color: "black",
                border: "none",
              },
            }}
          >
            Make a Reservation now
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default About;
