import React from 'react'
import { Box, Divider, Typography } from "@mui/material";
import clinic2 from "../photos/clinic2.jpeg";
import clinic1 from "../photos/clinic1.jpg";
import AppointmentBlock from '../components/Appointment';

function Appointment() {
  return (
    <>
      <Box sx={{ display: "flex", position: "relative",flexDirection:{xs:"column",md:"row"} }}>
        <Box
          sx={{
            width: {xs:"100%",md:"50%"},
            height: {md:"400px",xs:"200px"},
            background: `url(${clinic1}) left center no-repeat`,
            backgroundSize: "cover",
          }}
          ></Box>
        <Box
          sx={{
            width: {xs:"100%",md:"50%"},
            height: {md:"400px",xs:"200px"},
            background: `url(${clinic2}) left center no-repeat`,
            backgroundSize: "cover",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            width: {md:"500px",xs:"90%"},
            top: "50%",
            left: "50%",
            boxSizing: "border-box",
            color: "#fff",
            border: "2px solid black",
            transform: "translate(-50%,-50%)",
            backgroundColor: "rgba(5,6,10,.7)",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "start",
            fontFamily: "'Didact Gothic',sans-serif !important",
            gap: "20px",
            p: 2,
          }}
        >
          <Typography sx={{ color: "#fff", fontSize: {md: "24px", xs: "18px"}}}>
            MAKE AN APPOINTMENT
          </Typography>
          <Divider
            sx={{
              width: "20%",
              border: "1px solid #fff",
            }}
          />
          <Typography sx={{ color: "#fff", fontSize: {md: "22px", xs: "16px"}}}>
          To make an appointment please fill out the form below and we will be in contact soon.
          </Typography>
        </Box>
      </Box>
      <AppointmentBlock />
    </>
  )
}

export default Appointment