import { Divider, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import footerLogo from "../photos/logoFooter.svg";
import instaIcon from "../photos/instagram.svg";
import List from "@mui/material/List";
import facebookIcon from "../photos/facebook.svg";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

function Footer() {
  return (
    <Box sx={{ backgroundColor: "#21292c", color: "white", py: 3 }}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <Box
          sx={{
            width: { md: "30%", xs: "100%" },

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={footerLogo}
            width={250}
            height={250}
            alt="DuneAesthetics - By Dr. Aishwarya Bhatia"
          ></img>
          <Box sx={{ m: 2 }}>
            <a
              href="https://www.instagram.com/duneaesthetics?igshid=MzMyNGUyNmU2YQ=="
              alt="dune"
              target="_blank"
            >
              <img
                style={{ padding: "0px 10px" }}
                src={instaIcon}
                width={35}
                alt="Instagram icon"
              ></img>
            </a>
            <img src={facebookIcon} width={35} alt="facebook icon"></img>
          </Box>
        </Box>
        <Box
          sx={{
            width: { md: "20%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            pt: { md: 8, xs: 3 },
            pl: 3,
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
            QUICK LINKS
          </Typography>
          <Box sx={{ width: { md: "80%", xs: "100%" } }}>
            <ul
              style={{
                textDecoration: "none",
                listStyle: "none",
                padding: "0px",
                fontSize: "18px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <a href="/" style={{ textDecoration: "none", color: "white" }}>
                <li>Home</li>
              </a>
              <a
                href="/about"
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>About Us</li>
              </a>
              <a href="/" style={{ textDecoration: "none", color: "white" }}>
                <li>Our Services</li>
              </a>
              <a
                href="/appointment"
                style={{ textDecoration: "none", color: "white" }}
              >
                <li>Make an Appointment</li>
              </a>
            </ul>
          </Box>
        </Box>
        <Box
          sx={{
            width: { md: "30%", xs: "100%" },
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            pt: { md: 8, xs: 3 },
            pl: 3,
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            CONTACT US
          </Typography>
          <Box>
            <List>
              <ListItem sx={{ paddingLeft: "0px" }}>
                <ListItemIcon>
                  <LocationOnOutlinedIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "19px" }}
                  primary="1/3, Basement, Vikram Vihar, Lajpat Nagar IV, New Delhi, Delhi 110024"
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: "0px" }}>
                <ListItemIcon>
                  <LocalPhoneOutlinedIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "19px" }}
                  primary="Phone no: (+91) - 9667499600"
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: "0px" }}>
                <ListItemIcon>
                  <MailOutlineIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "19px" }}
                  primary="Email: info@duneaesthetics.com"
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            width: { md: "20%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
            pt: { md: 8, xs: 3 },
            pl: 3,
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
            OPENING HOURS
          </Typography>
          <Box sx={{ md: "80%", xs: "100%" }}>
            <ul
              style={{
                textDecoration: "none",
                listStyle: "none",
                padding: "0px",
                fontSize: "16px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <li>MON : 8 am - 8 pm</li>
              <li>TUE : 8 am - 8 pm</li>
              <li>WED : 8 am - 8 pm</li>
              <li>THU : 8 am - 8 pm</li>
              <li>FRI : 8 am - 8 pm</li>
              <li>SAT : 8 am - 8 pm</li>
            </ul>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          margin: "10px auto",
          border: "0.5px solid white",
        }}
      />
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: { xs: "15px" },
        }}
      >
        2023@Copyright of Duneaesthetics. All Rights Reserved
      </Box>
    </Box>
  );
}

export default Footer;
