import React from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Book1 from "../photos/book1.jpg";

function Book() {
  return (
    <Box
      style={{
        height: "300px",
        backgroundColor: "#374549",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: "url(" + Book1 + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      sx={{ display: "flex", flexDirection: { xs: "column", md: "row" },gap:"30px" }}
    >
      {/* <h1 style={{color:"white",fontSize:"40px",textTransform:"uppercase"}}>Skin Problems?<br /> No More.</h1> */}
      <Typography
        sx={{
          width: {md:"50%",xs:"90%"},
          textAlign: "center",
          fontSize: { md: "45px", xs: "35px" },
          fontWeight: 600,
          color: "white",
        }}
      >
        Look Your Best,
        <br /> <span style={{ color: "#E8BA63" }}>Feel Your Best</span>
        <br />
        <span style={{ fontSize: "18px", fontStyle: "italic", color: "" }}>
          <Typography sx={{fontSize:{xs:"12px",md:"18px",marginTop:"18px"}}}>
            We offer cutting-edge treatments that enhance your natural beauty &
            promote self-confidence.
          </Typography>
        </span>
      </Typography>

      <Button
        endIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 12h13M12 5l7 7-7 7" />
          </svg>
        }
        component={Link}
        to="/appointment"
        variant="contained"
        sx={{
          color: "black",
          margin: "0 auto",
          width: { md: "20%", xs: "60%" },
          padding: "6px",
          backgroundColor: "#cce0e5",
          border: "none",
          fontSize: { xs: 14, md: 18 },
          "&:hover": {
            backgroundColor: "#cce0e5",

            border: "none",
          },
        }}
      >
        Book Skin Care
      </Button>
    </Box>
  );
}

export default Book;
