import React, { useState, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import { Typography, Divider } from "@mui/material";

function Slideshow({ images, autoPlay = true, autoPlayTime = 2000 }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    if (currentSlide === images.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide === 0) {
      setCurrentSlide(images.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const newSlider =
        currentSlide >= images.length - 1 ? 0 : currentSlide + 1;
      setCurrentSlide(newSlider);
    }, autoPlayTime);
    return () => {
      clearTimeout(timer);
    };
  }, [currentSlide]);
  return (
    <>
      <Box
        className="mainContainer"
        sx={{ display: { xs: "none", md: "none" } }}
      >
        {images.map((image, index) => {
          return (
            <>
              <div
                className="slider"
                key={index}
                style={{
                  marginLeft:
                    index === 0 ? ` -${currentSlide * 100}%` : undefined,
                }}
              >
                <div className="sliderImg">
                  <img src={image.imageUrl} alt="Picture of the author" />
                </div>
                <div className="sliderText">
                  <Typography
                    sx={{
                      textAlign: "center",
                      lineHeight: "23.17px",
                      letterSpacing: "2.5px",
                      py: 1,
                    }}
                    variant="h6"
                  >
                    {image.imageTitle}
                  </Typography>
                  <Divider
                    sx={{
                      width: "8%",     
                      margin: "1px auto",
                      border: "2px solid black",
                      
                    }}
                  />

                  {/* <div className="slideAction">
                      <Button
                        sx={{ color: "white", borderColor: "white" }}
                        variant="outlined"
                        className="small_button"
                      >
                        Read more
                      </Button>
                    </div> */}
                </div>
                {/* <div className="indicatorWrapper">
          {Array(images.length)
            .fill(1)
            .map((item, index) => {
              return (
                <div
                  className="dots"
                  isActive={currentSlide === index}
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                ></div>
              );
            })}
        </div> */}
              </div>
            </>
          );
        })}
        <div className="navigatorRight">
          <ArrowForwardIosIcon className="small_button" onClick={nextSlide} />
        </div>
        <div className="navigatorLeft">
          <ArrowBackIosIcon className="small_button" onClick={prevSlide} />
        </div>
      </Box>
    </>
  );
}

export default Slideshow;
