import React, { createRef} from "react";
import Box from "@mui/material/Box";
import {
  Divider,
  Typography,
  CardActionArea,
} from "@mui/material";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import acne from "../photos/treatmentpics/acne.jpg";
import pig from "../photos/treatmentpics/pigmentation.jpg";
import hydra from "../photos/treatmentpics/hydra.jpg";
import aeging from "../photos/treatmentpics/ageing.jpg";
import boost from "../photos/treatmentpics/boost.jpg";
import mole from "../photos/treatmentpics/mole.jpg";
import laser from "../photos/treatmentpics/laser.jpg";
import iv from "../photos/treatmentpics/iv.jpg";
import tattoo from "../photos/treatmentpics/tattoo.jpg";
import { v1 as uuid } from "uuid";


function SkinTreatments() {
  // const ref1 = useRef(null);
  // const isInViewport = useIsInViewport(ref1);
  // console.log(isInViewport);
  const skinTreatmentData = [
    {
      id: uuid(),
      image: acne,
      title: "Acne/Acne Scar Treatment",
      link: "acne-scar-treatment",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: pig,
      title: "Pigmentation Treatment",
      link: "pigmentation-treatment",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: hydra,
      title: "HydraFacial Treatment",
      link: "hydrafacial-treatment",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: aeging,
      title: "Anti Ageing Treatment",
      link: "anti-ageing-treatment",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: boost,
      title: "Skin Boosters",
      link: "skin-boosters",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: mole,
      title: "Mole & Wart Removal",
      link: "mole-wart-removal",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: laser,
      title: "Laser Hair Reduction",
      link: "laser-hair-reduction",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: iv,
      title: "IV Drip Therapy",
      link: "iv-drip-therapy",
      nodeRef: createRef(null),
    },
    {
      id: uuid(),
      image: tattoo,
      title: "Tattoo Removal",
      link: "tattoo-removal",
      nodeRef: createRef(null),
    },
  ];

  return (
    <Box id="skin" sx={{ my: 6 }}>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "22px", xs: "20px" },
            letterSpacing: "3px",
            fontWeight: 600,
          }}
        >
          SKIN TREATMENTS
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "20px auto",
            border: "0.8px solid black",
            height: "40px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", md: "80%" },
          margin: "0 auto",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {skinTreatmentData.length !== 0 &&
          skinTreatmentData.map((skin, index) => {
            return (
              <Card
              
                ref={skin.nodeRef}
                sx={{
                  width: 305,
                  boxShadow:
                    "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;",
                  m: 2,
                  
                }}
              >
                <CardActionArea disableRipple component={Link} to={`/service/${skin.link}`}>
                  <CardMedia
                    component="img"
                    height="150"
                    image={skin.image}
                    alt="Skin treatment"
                    sx={{
                      borderRadius: "50%",
                      width: "150px",
                      margin: "20px auto",
                    }}
                  />
                  <CardContent sx={{paddingTop:"0px"}}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: {md:"24px",xs:"18px"},
                        my: {md:3,xs:1},
                        textAlign: "center",
                        height: {md:"60px",xs:"40px"},
                      }}
                    >
                      {skin.title}
                    </Typography>
                    <Divider
                      sx={{
                        width: "20%",
                        margin: "2px auto",
                        border: "2px solid black",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#70A3B5",
                        fontSize: {md:"18px",xs:"16px"},
                        textAlign: "center",
                        my: {md:3,xs:1},
                      }}
                    >
                      Learn More
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            );
          })}

      </Box>
    </Box>
  );
}

export default SkinTreatments;
