import { Box, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import check from "../photos/check.svg";

function Thankyou() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "90%", md: "50%" },
        margin: "auto 0",
        color: "white",
        justifyContent: "center",
        py: 4,
      }}
    >
      <Box sx={{}}>
        <img width={80} height={80} src={check} alt="tick mark"></img>
      </Box>
      <Box sx={{}}>
        <Typography variant="h4">Thank you!</Typography>
      </Box>
      <Box sx={{ color: "#D77056", mt: "10px", textAlign: "center" }}>
        <Typography sx={{ fontSize: { xs: "20px" } }} variant="h5">
          Your Enquiry has been received.
          <br /> We will get back to as soon as possible:)
        </Typography>
      </Box>
      <Box sx={{ mt: "50px" }}>Follow us on:</Box>
      <Box sx={{}}>
        <Box sx={{ flexGrow: 1, display: "flex", mt: "10px" }}>
          <Box
            sx={{
              display: "flex",
              width: "15%",
              color: "#D77056",
            }}
          >
            <a
              href="https://www.instagram.com/duneaesthetics?igshid=MzMyNGUyNmU2YQ=="
              target="_blank"
              style={{ textDecoration: "none", color: "#D77056" }}
            >
              <InstagramIcon
                sx={{
                  color: "inherit",
                  mx: "7px",
                  fontSize: "22px",
                }}
              />
            </a>
            <a
              href="#"
              target="_blank"
              style={{ textDecoration: "none", color: "#D77056" }}
            >
              <FacebookIcon
                sx={{
                  color: "inherit",
                  mx: "5px",
                  fontSize: "22px",
                }}
              />
            </a>
            {/* <a
              href="https://google.com"
              target="_blank"
              style={{ textDecoration: "none", color: "#D77056" }}
            >
              <TwitterIcon
                sx={{
               
                  color: "inherit",
                  mx: "7px",
                  fontSize: "22px",
                }}
              />
            </a> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Thankyou;
