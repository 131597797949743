// import { useLoadScript } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import LocationOnTwoToneIcon from "@mui/icons-material/LocationOnTwoTone";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import Thankyou from "./Thankyou";
import { gtag } from "ga-gtag";

const getFormattedDate = (objToday) => {
  const wow = objToday.$d.toString();
  var date = wow.substring(0, wow.indexOf(objToday.$y) + 4);
  return date;
};

function Appointment() {
  const [loading, setLoading] = useState(false);
  const [showThank, setShowThank] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [date, setDate] = useState(dayjs());
  const [actualDate, setActualDate] = useState("");
  const checkIndianPhoneRegex =
    /^(\+91)?(-)?\s*?(91)?\s*?(\d{3})-?\s*?(\d{3})-?\s*?(\d{4})/;
  const checkEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyBJtrIjXSvTtRat9rGxgig1OzpjY9mMyQg",
  // });
  // const center = useMemo(
  //   () => ({ lat: 28.564880379116307, lng: 77.24030113439974 }),
  //   []
  // );
  useEffect(() => {
    setActualDate(getFormattedDate(date));
  }, [date]);

  const handleUserData = async () => {
    const response = await fetch(
      "https://backend-duneaesthetic.vercel.app/updateUserData",
      {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          note: note,
          date: actualDate,
        }),
        mode: "cors",
        headers: {
          "content-type": "application/json; charset=utf-8",
        },
      }
    );
    // const data=response.json();

    if (response && response.status === 200) {
      setLoading(false);
      setName("");
      setEmail("");
      setPhone("");
      setNote("");
      setDate(dayjs());
      setShowThank(true);
    } else {
      alert("Something went Wrong!! Please try after some time");
    }
  };
  var callback = function () {
    if (typeof window.location.href != "undefined") {
      console.log("Google ads");
    }
  };

  const handleFormSubmit = async () => {
    try {
      if (
        name &&
        phone &&
        checkEmail.test(email) &&
        checkIndianPhoneRegex.test(phone)
      ) {
        gtag("event", "conversion", {
          send_to: "AW-11139494484/ipViCKXmg98YENTk3L8p",
          event_callback: callback,
        });

        setLoading(true);
        const response = await fetch(
          "https://backend-duneaesthetic.vercel.app/sendmail",
          {
            method: "POST",
            body: JSON.stringify({
              name: name,
              email: email,
              phone: phone,
              note: note,
              date: actualDate,
            }),
            mode: "cors",
            headers: {
              "content-type": "application/json; charset=utf-8",
            },
          }
        );
        console.log(response);
        console.log(response.status);
        if (response && response.status === 200) {
          handleUserData();
        } else if (response && response.status === 400) {
          setLoading(false);
          alert("Please enter all the mandatory details");
        } else if (response && response.status === 404) {
          setLoading(false);
          alert("Something went Wrong!! Please try after some time");
        } else {
          setLoading(false);
          alert("Something went Wrong!! Please try after some time");
        }
      } else {
        if (!name || !phone) {
          alert("Please enter all the mandatory details");
        } else if (!email || !checkEmail.test(email)) {
          alert("Please Enter correct email Id");
        } else if (!phone || !checkIndianPhoneRegex.test(phone)) {
          alert("Please Enter correct phone number");
        } else if (!name) {
          alert("Please enter all the mandatory details");
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert("Something went Wrong!! Please try after some time");
    }
  };

  return (
    <Box sx={{ my: 10 }}>
      <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "22px", xs: "20px" },
            letterSpacing: "3px",
            fontWeight: 600,
          }}
        >
          MAKE AN APPOINTMENT
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "20px auto",
            border: "0.8px solid black",
            height: "40px",
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#374549",

          width: { md: "100%", xs: "100%" },
          margin: "0 auto",
          display: "flex",
          flexDirection: { md: "row", xs: "column-reverse" },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { md: "50%", xs: "100%" },
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#F2F5F6",
          }}
        >
          <Box sx={{ width: { md: "80%", xs: "90%" } }}>
            <Typography
              sx={{
                fontSize: { md: "22px", xs: "20px" },
                fontWeight: "500",
                my: 1,
              }}
            >
              Visit Us :
            </Typography>
            <Box sx={{ height: "300px" }}>
              {/* {!isLoaded ? (
                <h1>Loading...</h1>
              ) : (
                <GoogleMap
                  mapContainerClassName="map-container"
                  center={center}
                  zoom={17}
                >
                  <Marker
                    onClick={() => {
                      window.open(
                        "https://maps.google.com?q=" +
                          28.5641077 +
                          "," +
                          77.2400651
                      );
                    }}
                    position={{ lat: 28.5641077, lng: 77.2400651 }}
                  />
                </GoogleMap>
              )} */}
              <iframe
                title="Dune Aesthetics - Best Skin Clinic in Delhi!"
                style={{ border: "0" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14016.731894800176!2d77.2370942!3d28.5642678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce303f853b551%3A0x3005cc2adb20308e!2sDune%20Aesthetics%20%7C%20Best%20Skin%20Clinic!5e0!3m2!1sen!2sin!4v1680607889748!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Box>

          <Box
            sx={{
              width: { md: "80%", xs: "90%" },
              margin: "20px auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: "10px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <LocationOnTwoToneIcon />
              <Typography sx={{ fontSize: { md: "18px", xs: "16px" }, pl: 1 }}>
                1/3, Basement, Vikram Vihar, Lajpat Nagar IV, Delhi 110024
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <PhoneTwoToneIcon />
              <Typography sx={{ fontSize: { md: "18px", xs: "16px" }, pl: 1 }}>
                (+91) - 9667499600
              </Typography>
            </Box>

            <Box></Box>
          </Box>
        </Box>
        {showThank ? (
          <Thankyou />
        ) : (
          <Box
            sx={{
              width: { md: "50%", xs: "100%" },
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { md: "80%", xs: "95%" },
                boxSizing: "border-box",
                margin: "40px auto",
                backgroundColor: "white",
                boxShadow: " 0px 20px 40px 0px #2A444B21",
                borderRadius: "5px",
                p: 3,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                <PersonOutlineIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  id="input-with-sx"
                  label="Name *"
                  variant="outlined"
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                <MailOutlineIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  fullWidth
                  id="input-with-sx"
                  label="Email *"
                  variant="outlined"
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                <LocalPhoneOutlinedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  fullWidth
                  id="input-with-sx"
                  label="Phone *"
                  variant="outlined"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  py: 1,
                  width: "100%",
                }}
              >
                <CalendarMonthIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    disablePast
                    sx={{ width: "100%" }}
                    onChange={(newDate) => setDate(newDate)}
                    value={date}
                    label="Appointment Date"
                    inputFormat="DD/MM/YYYY"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                <NoteAltOutlinedIcon
                  sx={{ color: "action.active", mr: 1, my: 0.5 }}
                />
                <TextField
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  fullWidth
                  select
                  id="input-with-sx"
                  label="Service You need"
                  variant="outlined"
                >
                  <MenuItem value="Pigmentation treatment">
                    Pigmentation treatment
                  </MenuItem>
                  <MenuItem value="Acne treatment">Acne treatment</MenuItem>
                  <MenuItem value="Skin lightening treatment">
                    Skin lightening treatment
                  </MenuItem>
                  <MenuItem value="Hydrafacial">Hydrafacial</MenuItem>
                  <MenuItem value="Hairfall treatment">
                    Hairfall treatment
                  </MenuItem>
                  <MenuItem value="Laser hair reduction">
                    Laser hair reduction
                  </MenuItem>
                  <MenuItem value="Fine lines and wrinkles treatment">
                  Fine lines and wrinkles treatment
                  </MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </TextField>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center", py: 1 }}>
                <NoteAltOutlinedIcon />
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={note}
                  fullWidth
                  label="Service"
                  
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </Box> */}
              <LoadingButton
                loading={loading}
                disabled={false}
                onClick={handleFormSubmit}
                fullWidth
                loadingPosition="end"
                variant="contained"
                sx={{
                  alignItems: "center",
                  color: "white",
                  backgroundColor: "#374549",
                  border: "none",
                  mt: 1,
                  ml: 4,
                  width: "calc(100% - 32px)",
                  fontSize: { md: "17px", xs: "15px" },
                  "&:hover": {
                    backgroundColor: "#EBF3F5",
                    color: "black",
                    borderColor: "black",
                  },
                }}
              >
                Book Your Reservation
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Appointment;
