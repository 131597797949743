import "./App.css";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Routes, Route} from "react-router-dom";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header1";
import Box from "@mui/material/Box";
import faces from "./photos/faces.svg";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Appointment from "./pages/Appointment";
import Services from "./pages/Services";
import ScrollToTop from "./components/ScrollToTop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Backdrop from "@mui/material/Backdrop";
import whatsapp from "./photos/whatsapp.svg";
import call from "./photos/call.png";
import callAction from "./photos/callAction.svg";
import appoint from "./photos/appoint.svg";
import { gtag, install } from "ga-gtag";

const actions = [
  {
    icon: <img src={whatsapp} alt="whatspp icon"></img>,
    name: "Contact on Whatsapp",
  },
  {
    icon: <img width={30} height={30} src={appoint} alt="Booking icon"></img>,
    name: "Book an Appointment",
  },
  {
    icon: <img width={48} height={48} src={call} alt="call icon"></img>,
    name: "Call now",
  },
];

const theme = createTheme({
  typography: {
    fontFamily: "'Barlow', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
});

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [access, setAccess] = useState(true);
  const [accessText, setAccessText] = useState("");
  const handleCallToAction = (name) => {
    if (name === "Contact on Whatsapp") {
      window.location.replace("https://wa.me/919667499600");
    } else if (name === "Call now") {
      window.location.replace("tel:+919667499600");
    } else {
      window.location.replace("/appointment");
    }
  };

  useEffect(() => {
    install("AW-11139494484");
    // const handleAccess = async () => {
    //   const response = await fetch(
    //     "https://backend-duneaesthetic.vercel.app/duneaesthetics",
    //     {
    //       method: "GET",
    //       headers: {
    //         "content-type": "application/json; charset=utf-8",
    //         "Access-Control-Allow-Origin":"https://duneaesthetics.com/"
    //       },
    //     }
    //   );
    //   const data = await response.json();
    //   if (response && response.status === 200) {
    //     setAccess(true);
    //   } else if (response && response.status === 404) {
    //     setAccessText(data.message);
    //     setAccess(false);
    //   }
    // };
    // handleAccess();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {access ? (
        <Box
          sx={{
            background: {
              md: `url(${faces}) center right no-repeat`,
              xs: `url(${faces}) center right no-repeat`,
            },
            position: "relative",
            backgroundAttachment: { xs: "fixed", md: "fixed" },

            flexGrow: 1,
            backgroundSize: { md: "250px 1200px", xs: "200px" },
          }}
        >
          <Header
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            setMobileOpen={setMobileOpen}
          />
          <Sidebar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            setMobileOpen={setMobileOpen}
          />
          <Backdrop sx={{ zIndex: "1050", color: "#000000" }} open={open} />
          <SpeedDial
            ariaLabel="SpeedDial controlled open example"
            sx={{
              position: "fixed",
              bottom: 25,
              right: 25,
              "& .MuiFab-primary": {
                backgroundColor: "#374549 !important",
                color: "white",
                width: { xs: "70px", md: "80px" },
                height: { xs: "70px", md: "80px" },
              },
            }}
            icon={<img width={55} height={55} src={callAction} alt="Call now icon"></img>}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {actions.map((action, index) => (
              <SpeedDialAction
                sx={{
                  "& #SpeedDialcontrolledopenexample-action-0-label": {
                    width: "150px",
                  },
                  "& #SpeedDialcontrolledopenexample-action-1-label": {
                    width: "150px",
                  },
                  "& #SpeedDialcontrolledopenexample-action-2-label": {
                    width: "60px",
                  },
                }}
                key={index}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipOpen
                onClick={(e) => {
                  handleCallToAction(action.name);
                }}
              />
            ))}
          </SpeedDial>

          <ScrollToTop />
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/about" Component={About} />
            <Route path="/appointment" Component={Appointment} />
            <Route path="/service/:name" Component={Services} />
          </Routes>
          <Footer />
        </Box>
      ) : (
        <div>{accessText}</div>
      )}
    </ThemeProvider>
  );
}

export default App;
