import React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography, CardActionArea } from "@mui/material";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import hairt from "../photos/treatmentpics/hairt.jpg";
import hairloss from "../photos/treatmentpics/hairloss.jpg";
import hairtreat from "../photos/treatmentpics/hairtreat.jpg";
import { Link } from "react-router-dom";

function HairTreatments() {
  const hairTreatmemntData = [
    {
      image: hairtreat,
      title: "Hairfall Treatment",
      link: "hairfall-treatment",
    },
    {
      image: hairt,
      title: "Hair Transplant",
      link: "hair-transplant",
    },
    {
      image: hairloss,
      title: "Platlet Rich Plasma (PRP)",
      link: "prp-hair",
    },
  ];
  return (
    <Box id="hair" sx={{ my: 6, zIndex: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", mt: {md:5,xs:1} }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "22px", xs: "20px" },
            letterSpacing: "3px",
            fontWeight: 600,
          }}
        >
          HAIR TREATMENTS
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "20px auto",
            border: "0.8px solid black",
            height: "40px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: { xs: "100%", md: "80%" },
          margin: "0 auto",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {hairTreatmemntData.map((skin, index) => {
          return (
            <Card
              key={index}
              sx={{
                width: 305,
                boxShadow:
                  "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;",
                m: 2,
              }}
            >
              <CardActionArea
                disableRipple
                component={Link}
                to={`/service/${skin.link}`}
              >
                <CardMedia
                  component="img"
                  height="150"
                  image={skin.image}
                  alt="Skin treatment"
                  sx={{
                    borderRadius: "50%",
                    width: "150px",
                    margin: "20px auto",
                  }}
                />
                <CardContent sx={{paddingTop:"0px"}}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: {md:"24px",xs:"18px"},
                      my: {md:3,xs:1},
                      textAlign: "center",
                      height: {md:"60px",xs:"40px"}
                    }}
                  >
                    {skin.title}
                  </Typography>
                  <Divider
                    sx={{
                      width: "20%",
                      margin: "2px auto",
                      border: "2px solid black",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#70A3B5",
                      fontSize: {md:"18px",xs:"16px"},
                      textAlign: "center",
                      my: {md:3,xs:1},
                    }}
                  >
                    Learn More
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
}

export default HairTreatments;
