
import WelcomeBanner from "../components/WelcomeBanner1";
import React, { useState, useEffect } from "react";
import SlideShow from "../components/Slideshow";
import pic1 from "../photos/pic1.jpg";
import pic2 from "../photos/pic2.jpg";
import pic3 from "../photos/pic3.jpg";
import About from "../components/About";
import Special from "../components/Special";
import SkinTreatments from "../components/SkinTreatments";
import HairTreatments from "../components/HairTreatments";
import AestheticTreatments from "../components/AestheticTreatments";
import Appointment from "../components/Appointment";
import OurTeam from "../components/OurTeam";
import Book from "../components/Book";

function Home() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages([
      {
        imageUrl: pic1,
        imageTitle: "SKIN",
        imageRedirectLink: "https://google.com",
      },
      {
        imageUrl: pic2,
        imageTitle: "HAIR",
        imageRedirectLink: "https://facebook.com",
      },
      {
        imageUrl: pic3,
        imageTitle: "AESTHETIC",
        imageRedirectLink: "https://facebook.com",
      },
    ]);
  }, []);
  return (
    <>
      <WelcomeBanner />
      <SlideShow images={images} />
      <About />
      <OurTeam />
      <Special />
      <SkinTreatments />
      <Book />
      <HairTreatments />
      <AestheticTreatments />
      <Appointment />
    </>
  );
}

export default Home;
