import React, { useState, useRef, useEffect, createRef } from "react";
import Box from "@mui/material/Box";
import { Divider, Typography, Zoom } from "@mui/material";
import useIsInViewport from "./useIsInViewport";
import unique from "../photos/unique.svg";
import exp from "../photos/exp.svg";
import gen from "../photos/genuine.svg";
import advance from "../photos/advance.svg";
import specialskinbg from "../photos/skinbg.jpg";

function Special() {
  const imgContainerRef = useRef(null);
  const specialContRef = useRef(null);
  const [isSpecialIsInViewportOnce, setIsSpecialIsInViewportOnce] =
    useState(false);

  // var isImageInViewport = useIsInViewport(imgContainerRef);
  var isSpecialInViewport = useIsInViewport(specialContRef);

  useEffect(() => {
    // if (isImageInViewport === true) {
    //   setIsImageIsInViewportOnce(true);
    // }
    if (isSpecialInViewport === true) {
      setIsSpecialIsInViewportOnce(true);
    }
  }, [isSpecialInViewport]);
  const specialData = [
    {
      imgSrc: unique,
      title: "Personalized Treatment",
      description:
        "A personalized treatment plan that meets your unique needs.",
      delay: 600,
      mdelay: 400,
      id: createRef(),
    },
    {
      imgSrc: advance,
      title: "Advance Technology",
      description:
        "A good skin clinic should be well-equipped with the latest technology and equipment.",
      delay: 1000,
      mdelay: 700,
      id: createRef(),
    },
    {
      imgSrc: exp,
      title: "Experienced Dermatologist",
      description: "We are highly experienced with 5+ years in industry.",
      delay: 1400,
      mdelay: 1000,
      id: createRef(),
    },
    {
      imgSrc: gen,
      title: "Genuine Quality Products",
      description:
        "We take great care in selecting and sourcing the products we use in our clinic.",
      delay: 1800,
      mdelay: 1300,
      id: createRef(),
    },
  ];

  return (
    <Box sx={{ my: {xs:4,md:12}, zIndex: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", my: {xs:1,md:4} }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "22px", xs: "20px" },
            letterSpacing: "3px",
            fontWeight: 600,
          }}
        >
          WHY US
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "20px auto",
            border: "0.8px solid black",
            height: "40px",
          }}
        />
      </Box>
      <Box>
        <Box
          sx={{
            width: { md: "80%", xs: "95%" },
            margin: "0px auto",
            py: { md: 2, xs: 0 },
            display: "flex",
            position: "relative",
            background: {
              md: `url(${specialskinbg}) center center no-repeat`,
              xs: "none",
            },
            backgroundSize: { md: "cover", xs: "initial" },
            flexDirection: { xs: "column-reverse", md: "row" },
            borderRadius: "5px",
            boxShadow: {
              md: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              xs: "",
            },
          }}
        >
          <Box
            ref={imgContainerRef}
            sx={{
              width: { md: "48%", xs: "100%" },
              margin: "16px auto",
              boxSizing: "border-box",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              pl: { md: 5 },
              gap: "10px",
              pt: { xs: 6, md: 20 },
              px: "10px",
            }}
          >
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
                fontSize: { md: "30px", xs: "20px" },
                fontWeight: "600",
              }}
            >
              Claim upto 50% off
              <br /> on the most popular
              <br /> services...
            </Typography>
            <Typography
              sx={{
                display: { xs: "block", md: "none" },
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Claim upto 50% off on the most popular services...
            </Typography>
            <Typography sx={{ width: { md: "80%", xs: "100%" }, mb: 1 }}>
              Looking for affordable ways to take care of your skin? Look no
              further! Our Clinic proudly offers discounted skin services to
              help you look and feel your best without breaking the bank. We
              take great care in selecting and sourcing the products we use in
              our Clinic.
            </Typography>
            <Box
              sx={{
                width: { md: "80%", xs: "100%" },
                height: "110",
                border: "1px solid black",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "65%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  ml: 3,
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      gap: "2px",
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#374549",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#374549",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#374549",
                      }}
                    ></Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { md: "15px", xs: "15px" },
                        fontWeight: "500",
                      }}
                    >
                      Mail Us :
                      <Typography sx={{ fontSize: { md: "15px", xs: "15px" } }}>
                        info@duneaesthetics.com
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      gap: "2px",
                      mt: 1,
                    }}
                  >
                    <Box
                      sx={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#374549",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#374549",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        height: "8px",
                        width: "8px",
                        borderRadius: "50%",
                        backgroundColor: "#374549",
                      }}
                    ></Box>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { md: "15px", xs: "15px" },
                        fontWeight: "500",
                      }}
                    >
                      Contact Us :
                      <Typography sx={{ fontSize: { md: "15px", xs: "15px" } }}>
                        (+91) - 9667499600
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "35%",
                  backgroundColor: "#374549",
                }}
              >
                <Divider sx={{ color: "white", borderColor: "#ffffff" }}>
                  Get Flat{" "}
                </Divider>
                <Typography
                  sx={{
                    fontSize: { md: "24px", xs: "21px" },
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  50%
                  <br />
                  Discount
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: { md: "55%", xs: "100%" },
              display: "flex",
              flexWrap: "wrap",
              flexGrow: 1,
              boxSizing: "border-box",
              justifyContent: "center",
              gap: { md: "20px", xs: "10px" },
            }}
          >
            {specialData.map((data, index) => {
              return (
                <Box
                  sx={{
                    width: {
                      md: "45%",
                      xs: "90%",
                    },
                    my: 0.5,
                  }}
                  ref={specialContRef}
                >
                  <Zoom
                    in={isSpecialIsInViewportOnce}
                    direction="up"
                    container={specialContRef.current}
                    mountOnEnter
                    {...(isSpecialIsInViewportOnce
                      ? { timeout: data.delay }
                      : {})}
                  >
                    <Box
                      sx={{
                        textAlign: { md: "left", xs: "center" },
                        height: { md: "280px", xs: "auto" },

                        py: { xs: 3, md: 1 },
                        px: 2,
                        boxSizing: "border-box",
                        width: "100%",
                        borderRadius: "7px",
                        backgroundColor: "rgb(254,255,255)",
                        boxShadow:
                          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;",
                      }}
                    >
                      <img
                        src={data.imgSrc}
                        width={80}
                        height={80}
                        className="specialImagesForMobile"
                        alt="Personlized treatment"
                      />
                      <Typography
                        sx={{
                          fontSize: { md: "26px", xs: "22px" },
                          fontFamily: "Playfair Display",
                          fontWeight: "bold",
                        }}
                      >
                        {data.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { md: "18px", xs: "15px" },
                          py: { xs: 2, md: 0 },
                        }}
                      >
                        {data.description}
                      </Typography>
                    </Box>
                  </Zoom>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Special;
