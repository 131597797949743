import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import pic1 from "../photos/pic1.jpg";
import pic2 from "../photos/pic2.jpg";
import pic3 from "../photos/pic3.jpg";

function WelcomeBanner1() {
  const scroll = (scrollTo) => {
    const section = document.querySelector(`#${scrollTo}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <Box
      sx={{
        display: { xs: "flex", md: "flex" },
        flexDirection: { md: "row", xs: "column" },
        alignItems: "center",
        justifyContent: "center",
        width: { md: "85%", xs: "100%" },
        margin: { md: "20px auto", xs: "0 auto" },
      }}
    >
       {/* ----Skin Section----------- */}
      <Box
        onClick={() => scroll("skin")}
        sx={{
          width: "100%",

          mx: { md: 2, xs: 0 },
          mb: { md: 0, xs: 1 },
          display: "flex",
          height:{xs:"300px",md:"auto"},
          backgroundImage: {xs:`url(${pic1})`,md:'none'},
          backgroundSize: "cover",
          backgroundPosition: "center",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: {
            md: "column",
            xs: "row-reverse",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
            mr: { md: 0, xs: 1 },
            "&:hover": {
              cursor: "pointer",
              "& img": {
                transform: { md: "scale(1.2)", xs: "" },
                transition: "all 400ms",
              },
              "& div": {
                opacity: "0.3",
                visibility: "visible",
                transition: "all 400ms",
              },
            },
          }}
        >
          <img className="bannerImagesMobile" src={pic1} alt=""></img>
          <div className="overlay"></div>
        </Box>
        {/* mobile typography for skin */}
        <Box
          sx={{
            borderRadius: "0px 50px 50px 0px",
            height: "40px",
            px:3,
            position: "relative",      
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "#EBF3F5",
            color: "black",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              lineHeight: "23.17px",
              letterSpacing: "2.5px",
              py: 1,
              fontSize: "18px" ,
            }}
            variant="h6"
          >
            SKIN
          </Typography>
        </Box>
        {/* mobile typography for skin */}
        {/* Desktop typography for skin */}
        <Typography
          sx={{
            display: { xs: "none", md: "block" },
            textAlign: "center",
            lineHeight: "23.17px",
            letterSpacing: "2.5px",
            py: 1,
            fontSize: { md: "18px", xs: "20px" },
          }}
          variant="h6"
        >
          SKIN
        </Typography>
        <Divider
          sx={{
            display: { xs: "none", md: "block" },
            width: "15%",
            margin: "2px auto",
            border: "2px solid black",
            borderRadius: "50%",
            "@media (max-width: 990px)": {
              width: "55%",
            },
          }}
        />
        {/* Desktop typography for skin */}
      </Box>
      {/* ----Aesthetics Section----------- */}
      <Box
        onClick={() => scroll("aes")}
        sx={{
          width: "100%",
          mx: { md: 2, xs: 0 },
          my: { md: 0, xs: 1 },
          display: "flex",
          alignItems: "center",
          
          height:{xs:"300px",md:"auto"},
          backgroundImage: {xs:`url(${pic3})`,md:'none'},
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          justifyContent: "space-between",
          flexDirection: {
            md: "column",
            xs: "row",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
            ml: { md: 0, xs: 1 },
            "&:hover": {
              cursor: "pointer",
              "& img": {
                transform: { md: "scale(1.2)", xs: "" },
                transition: "all 400ms ease-in-out",
              },
              "& div": {
                opacity: "0.3",
                visibility: "visible",
                transition: "all 400ms ease-in-out",
              },
            },
          }}
        >
          <img className="bannerImagesMobile" src={pic3} alt=""></img>
          <div className="overlay"></div>
        </Box>
        <Box
          sx={{
            borderRadius: "50px 0px 0px 50px",
            height: "40px",
            px:3,
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "#EBF3F5",
            color: "black",
          }}
        >
          <Typography
            sx={{
              overflow: "hidden",
              textAlign: "center",
              lineHeight: "23.17px",
              letterSpacing: "2.5px",
              py: 1,
              fontSize: "18px" ,
            }}
            variant="h6"
          >
            AESTHETICS
          </Typography>
        </Box>
        <Typography
          sx={{
            display: { xs: "none", md: "block" },
            overflow: "hidden",
            textAlign: "center",
            lineHeight: "23.17px",
            letterSpacing: "2.5px",
            py: 1,
            fontSize: { md: "18px", xs: "20px" },
          }}
          variant="h6"
        >
          AESTHETICS
        </Typography>
        <Divider
          sx={{
            display: { xs: "none", md: "block" },
            width: "15%",
            margin: "2px auto",
            border: "2px solid black",
            borderRadius: "50%",
            "@media (max-width: 990px)": {
              width: "55%",
            },
          }}
        />
      </Box>
      {/* ----Hair Section----------- */}
      <Box
        onClick={() => scroll("hair")}
        sx={{
          width: "100%",
          mx: { md: 2, xs: 0 },
          my: { md: 0, xs: 1 },
          display: "flex",
          alignItems: "center",
          height:{xs:"300px",md:"auto"},
          backgroundImage: {xs:`url(${pic2})`,md:'none'},
          backgroundSize: "cover",
          backgroundRepeat:"no-repeat",
          backgroundPosition: "center center",
          justifyContent: "space-between",
          flexDirection: {
            md: "column",
            xs: "row-reverse",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
            mr: { md: 0, xs: 1 },
            "&:hover": {
              cursor: "pointer",
              "& img": {
                transform: { md: "scale(1.2)", xs: "" },
                transition: "all 400ms",
              },
              "& div": {
                opacity: "0.3",
                visibility: "visible",
                transition: "all 400ms",
              },
            },
          }}
        >
          <img className="bannerImagesMobile" src={pic2} alt=""></img>
          <div className="overlay"></div>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            borderRadius: "0px 50px 50px 0px",
            height: "40px",
            px:3,
            position: "relative",           
            display: { xs: "flex", md: "none" },
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#EBF3F5",
            color: "black",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              lineHeight: "23.17px",
              letterSpacing: "2.5px",
              border: "1px soldid black",
              py: 1,
              fontSize: "18px"
            }}
            variant="h6"
          >
            HAIR
          </Typography>
        </Box>
        <Typography
          sx={{
            textAlign: "center",
            lineHeight: "23.17px",
            display: { xs: "none", md: "block" },
            letterSpacing: "2.5px",
            border: "1px soldid black",
            py: 1,
            fontSize: { md: "18px", xs: "20px" },
          }}
          variant="h6"
        >
          HAIR
        </Typography>
        <Divider
          sx={{
            display: { xs: "none", md: "block" },
            width: "15%",
            margin: "2px auto",
            border: "2px solid black",
            borderRadius: "50%",
            "@media (max-width: 990px)": {
              width: "55%",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default WelcomeBanner1;
