import React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import { AnimatePresence, motion} from "framer-motion";

function About() {
  return (
    <Box sx={{ mt: {md:15,xs:5}, minHeight: {md:"300px",xs:"700px",md:"450px"} }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "22px", xs: "20px" },
            letterSpacing: "3px",
            fontWeight: 600,
          }}
        >
          ABOUT US
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "20px auto",
            border: "0.8px solid black",
            height: "40px",
          }}
        />
      </Box>

      <Box
        sx={{
          textAlign: {xs:"left",md:"center"},
          position: "relative",
          width: { md: "80%", xs: "95%" },
          margin: "0 auto",
        }}
      >
        <AnimatePresence>
          <motion.div
            style={{ position: "absolute" }}
            initial={{ y: 100, opacity: 0 }}
            whileInView="visible"
            viewport={{ once: true }}
            variants={{
              visible: { y: 0, opacity: 1 },
              hidden: { y: -100, opacity: 0 },
            }}
            transition={{ duration: 0.8 }}
            
          >
            <Typography
              sx={{
                fontFamily: "'Didact Gothic', sans-serif",
                fontSize: { md: "23px", xs: "18px" },
                letterSpacing: "1px",
                padding:"6px"
              }}
            >
              Welcome to Dune Aesthetics Clinic by Dr Aishwarya Bhatia! With
              over five years of experience, Dr Aishwarya Bhatia is a trusted
              and knowledgeable Skin Doctor dedicated to helping you achieve
              beautiful, healthy skin.
              <br />
              Our clinic offers a variety of advanced skincare treatments,
              including chemical peels, microdermabrasion, dermal fillers,
              medical facials, micro-needling, laser hair reduction and more. We
              use only the latest technologies and techniques to ensure you
              receive the best possible care. Dr Aishwarya Bhatia and her team
              are committed to working with you to develop a personalized
              treatment plan that meets your unique needs and goals. Please book
              an appointment with us today and let us help you achieve the
              radiant, youthful-looking skin you deserve!
            </Typography>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
}

export default About;
