import React from "react";
import Box from "@mui/material/Box";
import { Drawer } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DatasetRoundedIcon from "@mui/icons-material/DatasetRounded";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import hairIcon from "../photos/icons/hair.svg";
import skinIcon from "../photos/icons/skin.svg";
import aesIcon from "../photos/icons/aesthetics.svg";
import CallIcon from "@mui/icons-material/Call";
import logoBottom from "../photos/logoFooter.svg";
import Button from "@mui/material/Button";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

function Sidebar(props) {
  const drawerWidth = 290;
  const { window } = props;
  const [openServices, setOpenServices] = React.useState(false);
  const [openHairServices, setOpenHairServices] = React.useState(false);

  const handleOpenHairServices = () => {
    setOpenHairServices(!openHairServices);
  };
  const [openSkinServices, setOpenSkinServices] = React.useState(false);

  const handleOpenSkinServices = () => {
    setOpenSkinServices(!openSkinServices);
  };
  const [openAestheticServices, setOpenAestheticServices] =
    React.useState(false);

  const handleOpenAestheticServices = () => {
    setOpenAestheticServices(!openAestheticServices);
  };
  const handleServicesClick = () => {
    setOpenServices(!openServices);
  };

  const menuItemList = [
    {
      text: "Home",
      icon: <HomeRoundedIcon />,
      to: "/",
    },
    {
      text: "Services",
      icon: <DatasetRoundedIcon />,
      to: "#",
    },
    {
      text: "About Us",
      icon: <EmojiEmotionsIcon />,
      to: "/about",
    },
    {
      text: "Contact Us",
      icon: <CallIcon />,
      to: "/appointment",
    },
  ];
  const secondaryMenuList = [
    {
      text: "Hair",
      icon: hairIcon,
      to: "/",
      alt: "Hair combing and treatment icon",
    },
    {
      text: "Skin",
      icon: skinIcon,
      to: "/profile",
      alt: "Skin care treatment icon",
    },
    {
      text: "Aesthetics",
      icon: aesIcon,
      to: "/profile",
      alt: "Aesthetic surgery treatment icon",
    },
  ];
  const hairMenuList = [
    {
      text: "Hairfall Treatment",
      to: "hairfall-treatment",
    },
    {
      text: "Hair Transplant",
      to: "hair-transplant",
    },
    {
      text: "Platlet Rich Plasma (PRP)",
      to: "prp-hair",
    },
  ];
  const skinMenuList = [
    {
      text: "Acne/Acne scar Treatment",
      to: "acne-scar-treatment",
    },
    {
      text: "Pigmentation Treatment",
      to: "pigmentation-treatment",
    },
    {
      text: "HydraFacial Treatment",
      to: "hydrafacial-treatment",
    },
    {
      text: "Anti Ageing Treatment",
      to: "anti-ageing-treatment",
    },
    {
      text: "Mole & Wart Removal",
      to: "mole-wart-removal",
    },
    {
      text: "Skin Boosters",
      to: "skin-boosters",
    },
    {
      text: "Laser Hair Reduction",
      to: "laser-hair-reduction",
    },
    {
      text: "IV Drip Therapy",
      to: "iv-drip-therapy",
    },
    {
      text: "Tattoo Removal",
      to: "tattoo-removal",
    },
  ];
  const aestheticMenuList = [
    {
      text: "Platlet Rich Plasma (PRP)",
      to: "prp",
    },
    {
      text: "Liquid FaceLift - Fillers/Anti ageing",
      to: "Liquid-FaceLift-Fillers-Anti-ageing",
    },
    {
      text: "Oxygeneo Facial",
      to: "Oxygeneo-Facial",
    },
    {
      text: "Q-Switch/Carbon Facial",
      to: "qswitch-carbon-facial",
    },
  ];

  const drawer = (
    <div>
      <List>
        {menuItemList.map((item, index) => {
          const { text, icon } = item;
          return index !== 1 ? (
            <ListItemButton
              key={index}
              component={Link}
              to={`${item.to}`}
              onClick={() => props.setMobileOpen(false)}
              sx={{ my: 2, color: "white" }}
            >
              <ListItemIcon
                sx={{ color: "inherit" }}
                className="iconHoverColor"
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={text} className="iconHoverColor" />
            </ListItemButton>
          ) : (
            <>
              <ListItemButton
                key={index}
                sx={{ my: 2, color: "white" }}
                onClick={handleServicesClick}
              >
                <ListItemIcon sx={{ color: "inherit" }}>{icon}</ListItemIcon>
                <ListItemText primary={text} />
                {openServices ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                key={index}
                in={openServices}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {secondaryMenuList.map((item, index) => {
                    return (
                      <>
                        {index === 0 ? (
                          <>
                            <ListItemButton
                              sx={{ pl: 4, my: 1, color: "white" }}
                              onClick={handleOpenHairServices}
                            >
                              <ListItemIcon>
                                <img
                                  width={30}
                                  height={30}
                                  src={item.icon}
                                  alt={item.alt}
                                ></img>
                              </ListItemIcon>
                              <ListItemText primary={item.text} />
                              {openHairServices ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemButton>
                            <Collapse
                              in={openHairServices}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {hairMenuList.map((item, index) => {
                                  return (
                                    <ListItemButton
                                      component={Link}
                                      to={`/service/${item.to}`}
                                      onClick={() => props.setMobileOpen(false)}
                                      sx={{ pl: 6, my: 1, color: "white" }}
                                    >
                                      <ListItemText primary={item.text} />
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </Collapse>
                          </>
                        ) : index === 1 ? (
                          <>
                            <ListItemButton
                              sx={{ pl: 4, color: "white" }}
                              onClick={handleOpenSkinServices}
                            >
                              <ListItemIcon>
                                <img
                                  width={30}
                                  height={30}
                                  src={item.icon}
                                  alt={item.alt}
                                ></img>
                              </ListItemIcon>
                              <ListItemText primary={item.text} />
                              {openSkinServices ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemButton>
                            <Collapse
                              in={openSkinServices}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {skinMenuList.map((item, index) => {
                                  return (
                                    <ListItemButton
                                      component={Link}
                                      to={`/service/${item.to}`}
                                      onClick={() => props.setMobileOpen(false)}
                                      sx={{ pl: 6, my: 1, color: "white" }}
                                    >
                                      <ListItemText primary={item.text} />
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </Collapse>
                          </>
                        ) : (
                          <>
                            <ListItemButton
                              sx={{ pl: 4, my: 1, color: "white" }}
                              onClick={handleOpenAestheticServices}
                            >
                              <ListItemIcon>
                                <img
                                  width={30}
                                  height={30}
                                  src={item.icon}
                                  alt={item.alt}
                                ></img>
                              </ListItemIcon>
                              <ListItemText primary={item.text} />
                              {openAestheticServices ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItemButton>
                            <Collapse
                              in={openAestheticServices}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {aestheticMenuList.map((item, index) => {
                                  return (
                                    <ListItemButton
                                      component={Link}
                                      to={`/service/${item.to}`}
                                      onClick={() => props.setMobileOpen(false)}
                                      sx={{ pl: 6, my: 1, color: "white" }}
                                    >
                                      <ListItemText primary={item.text} />
                                    </ListItemButton>
                                  );
                                })}
                              </List>
                            </Collapse>
                          </>
                        )}
                      </>
                    );
                  })}
                </List>
              </Collapse>
            </>
          );
        })}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            top: "auto",
            backgroundColor: "#374549",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            width={200}
            height={180}
            src={logoBottom}
            alt="Dune Aesthetics - Best Skin Clinic"
          ></img>
        </Box>
        {drawer}
        <Button
          onClick={props.handleDrawerToggle}
          component={Link}
          to="/appointment"
          fullWidth
          variant="outlined"
          sx={{
            alignItems: "center",
            color: "black",
            backgroundColor: "#EBF3F5",
            border: "none",
            fontSize: "15px",
            borderRadius: "0px",
            "&:hover": {
              backgroundColor: "#cce0e5",
              color: "black",
              border: "none",
            },
          }}
        >
          Book Your Appointment
        </Button>
        <Box
          sx={{
            display: "flex",
            mx: "20px",
            my: "20px",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="https://www.instagram.com/duneaesthetics?igshid=MzMyNGUyNmU2YQ=="
            target="_blank"
          >
            <InstagramIcon
              sx={{
                color: "inherit",
                mx: "7px",
                fontSize: "18px",
              }}
            />
          </a>
          <a>
            <FacebookIcon
              sx={{
                color: "inherit",
                mx: "5px",
                fontSize: "18px",
              }}
            />
          </a>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Sidebar;
