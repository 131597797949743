import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import skinPic from "../photos/wow.jpg";
import { useParams } from "react-router-dom";
import one from "../photos/new_images/1.png";
import two from "../photos/new_images/2.png";
import three from "../photos/new_images/3.png";
import four from "../photos/new_images/4.png";
import thirteen from "../photos/new_images/13.png";
import seven from "../photos/new_images/7.png";
import five from "../photos/new_images/5.png";
import ten from "../photos/new_images/10.png";
import eleven from "../photos/new_images/11.png";
import six from "../photos/new_images/6.png";
import twenty_three from "../photos/new_images/23.jpg";
import twenty_five from "../photos/new_images/25.jpg";
import twenty_four from "../photos/new_images/24.jpg";
import twenty_two from "../photos/new_images/22.jpg";
import eighteen from "../photos/new_images/18.jpg";
import nineteen from "../photos/new_images/19.jpg";
import twenty from "../photos/new_images/20.jpg";
import twenty_six from "../photos/new_images/26.jpg";
import twenty_one from "../photos/new_images/21.jpg";
import seventeen from "../photos/new_images/17.png";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AppointmentBlock from "../components/Appointment";

function Services() {
  const { name } = useParams();

  const ServiceData = [
    {
      id: "skin",
      name: "Acne/Acne Scar Treatment",
      link: "acne-scar-treatment",
      ba_image: [eleven, one, five],
      description:
        "Acne is a common skin condition when hair follicles become clogged with oil and dead skin cells. Acne scars are the result of inflammation caused by acne. When acne lesions become inflamed, the skin tissue is damaged, and the body produces collagen to repair it. If too much collagen is produced, it can create a raised scar, while too little can lead to a depressed scar. ",
      quesAndAnswer: [
        {
          question: "Can acne scars be removed entirely?",
          answer:
            "Acne scars can be improved but may not be removed entirely. The extent of improvement depends on the type and severity of the scar, the patient's skin type, and the chosen treatment method. Some scars may require multiple treatment sessions to achieve the desired results.",
        },
        {
          question: "Are acne scar treatments painful?",
          answer:
            "Most acne scar treatments are minimally invasive and involve little to no pain. Some treatments may cause mild discomfort, and your dermatologist may recommend using a topical numbing cream to reduce discomfort.",
        },
        {
          question:
            "How long does it take to see results from acne scar treatment?",
          answer:
            "The time it takes to see results from acne scar treatment varies depending on the treatment method. Some treatments may provide immediate results, while others may require several weeks or even months to show improvement. Your dermatologist will provide you with an estimate of how long it will take to see results based on your chosen treatment method.",
        },
        {
          question: "Are acne scar treatments safe?",
          answer:
            "Acne scar treatments are generally safe when performed by a licensed and experienced dermatologist. However, some treatments may carry a risk of side effects or complications. Your dermatologist will discuss each treatment method's potential risks and benefits with you.",
        },
      ],
    },
    {
      id: "aes",
      name: "Liquid FaceLift-Fillers/Anti Ageing Treatment",
      link: "Liquid-FaceLift-Fillers-Anti-ageing",
      ba_image: [three, four],
      description:
        "Liquid facelift is a non-surgical cosmetic treatment that involves injecting dermal fillers and fine lines into specific areas of the face to restore volume, smooth out wrinkles, and improve the overall appearance.",
      quesAndAnswer: [
        {
          question: "What are dermal fillers?",
          answer:
            "Dermal fillers are injectable substances that help restore volume to the face, reducing the appearance of wrinkles and fine lines. The most common fillers in a liquid facelift are hyaluronic acid fillers, such as Juvederm or Restylane.",
        },
        {
          question: "What are Anti ageing, fine lines and wrinkles treatment?",
          answer:
            "It is a neuromodulator injected into the facial muscles to temporarily relax them and reduce the appearance of wrinkles and fine lines.",
        },
        {
          question:
            "What areas of the face can be treated with a liquid facelift?",
          answer:
            "A liquid facelift can be used to treat a variety of areas, including the cheeks, temples, forehead, brows, under-eye area, nasolabial folds, marionette lines, lips, and chin.",
        },
        {
          question: "Is a liquid facelift painful?",
          answer:
            "Most patients experience only mild discomfort during the injections. Using a topical anaesthetic cream or ice can also help minimize discomfort.",
        },
        {
          question: "How long does a liquid facelift last?",
          answer:
            "The effects of a liquid facelift typically last anywhere from six months to two years, depending on the specific products used and the individual patient's response to the treatment.",
        },
        {
          question: "Can anyone get a liquid facelift?",
          answer:
            "While a liquid facelift is generally considered safe and effective for most people, some contraindications include pregnancy, breastfeeding, and certain medical conditions. Discussing your medical history with your healthcare provider before undergoing a liquid facelift is essential.",
        },
        {
          question: "How much does a liquid facelift cost?",
          answer:
            "The cost of a liquid facelift varies depending on the specific products used and the number of injections required. The total cost may range from a few hundred to several thousand dollars. Discussing the cost with your healthcare provider before the procedure is essential.",
        },
      ],
    },
    {
      id: "aes",
      name: "Oxygeneo Facial",
      link: "Oxygeneo-Facial",
      ba_image: [twenty_one],
      description:
        "OxyGeneo Facial is a non-invasive skin treatment combining three skin-enhancing procedures: exfoliation, infusion, and oxygenation. The treatment uses a unique handpiece that produces carbon dioxide bubbles on the skin's surface, which gently exfoliates the skin and stimulates oxygenation, followed by the infusion of nutrient-rich serums.",
      quesAndAnswer: [
        {
          question: "What are the benefits of OxyGeneo Facial?",
          answer:
            "OxyGeneo Facial is a non-invasive treatment with several benefits, including improved skin texture and tone, reduced fine lines and wrinkles, increased hydration, and improved skin elasticity. The treatment also enhances the skin's overall appearance and provides a youthful, radiant glow.",
        },
        {
          question: "How many treatments are required?",
          answer:
            "The number of treatments required can vary depending on the individual and the specific skin concerns being addressed. A series of treatments spaced several weeks apart may be needed to achieve the best results.",
        },
        {
          question: "How does OxyGeneo Facial work?",
          answer:
            "The OxyGeneo Facial treatment begins with gentle skin exfoliation, followed by the infusion of nutrient-rich serums. The unique handpiece produces carbon dioxide bubbles on the skin's surface, which gently exfoliates the skin and stimulates oxygenation. The infusion of serums contains active ingredients like hyaluronic acid, peptides, and antioxidants that help to nourish and rejuvenate the skin.",
        },
        {
          question: "Is there any downtime after the treatment?",
          answer:
            "There is generally no downtime after an OxyGeneo Facial, and most people can return to normal activities immediately after the treatment. Some redness and mild swelling may be experienced, but this usually subsides within a few hours. It's essential to avoid sun exposure and use sunscreen after the treatment.",
        },
      ],
    },
    {
      id: "skin",
      name: "Platlet Rich Plasma (PRP)",
      link: "prp",
      ba_image: [seven, ten],
      description:
        "PRP, or platelet-rich plasma, is a treatment that uses the patient's blood to promote healing and regeneration. The procedure involves drawing a small amount of the patient's blood, processing it to concentrate the platelets and growth factors, and injecting the resulting solution into the treatment area.",
      quesAndAnswer: [
        {
          question: "What is PRP used for?",
          answer:
            "PRP is commonly used to promote healing and regeneration in various conditions, including hair loss, skin rejuvenation, joint pain, and sports injuries.",
        },
        {
          question: "Is PRP safe?",
          answer:
            "PRP is generally considered safe, as it uses the patient's blood and is minimally invasive. However, as with any medical procedure, there are potential risks and side effects, such as infection, bleeding, and pain.",
        },
        {
          question: "Does PRP hurt?",
          answer:
            "The injection may cause some discomfort, but most patients report only mild discomfort during the procedure.",
        },
        {
          question: "How long does a PRP treatment take?",
          answer:
            "The entire PRP treatment process typically takes less than an hour.",
        },
        {
          question: "How many PRP treatments are needed?",
          answer:
            "The number of PRP treatments needed depends on the specific condition and the patient's response. Many patients experience significant improvement after one treatment, while others may require several treatments.",
        },
        {
          question: "How long do the effects of PRP last?",
          answer:
            "The effects of PRP can last several months to a year or more, depending on the patient's response to the treatment.",
        },
      ],
    },
    {
      id: "hair",
      name: "Platlet Rich Plasma (PRP)",
      link: "prp-hair",
      ba_image: [twenty_four],
      description:
        "PRP, or platelet-rich plasma, is a treatment that uses the patient's blood to promote healing and regeneration. The procedure involves drawing a small amount of the patient's blood, processing it to concentrate the platelets and growth factors, and injecting the resulting solution into the treatment area.",
      quesAndAnswer: [
        {
          question: "What is PRP used for?",
          answer:
            "PRP is commonly used to promote healing and regeneration in various conditions, including hair loss, skin rejuvenation, joint pain, and sports injuries.",
        },
        {
          question: "Is PRP safe?",
          answer:
            "PRP is generally considered safe, as it uses the patient's blood and is minimally invasive. However, as with any medical procedure, there are potential risks and side effects, such as infection, bleeding, and pain.",
        },
        {
          question: "Does PRP hurt?",
          answer:
            "The injection may cause some discomfort, but most patients report only mild discomfort during the procedure.",
        },
        {
          question: "How long does a PRP treatment take?",
          answer:
            "The entire PRP treatment process typically takes less than an hour.",
        },
        {
          question: "How many PRP treatments are needed?",
          answer:
            "The number of PRP treatments needed depends on the specific condition and the patient's response. Many patients experience significant improvement after one treatment, while others may require several treatments.",
        },
        {
          question: "How long do the effects of PRP last?",
          answer:
            "The effects of PRP can last several months to a year or more, depending on the patient's response to the treatment.",
        },
      ],
    },
    {
      id: "hair",
      name: "Hair Transplant",
      link: "hair-transplant",
      ba_image: [twenty_five],
      description:
        "Hair transplant is a surgical procedure that involves removing hair follicles from one area of the body, typically the back or sides of the head, and transplanting them to the balding or thinning areas of the scalp. The transplanted hair follicles continue to grow hair in their new location, leading to improved hair density and appearance.",
      quesAndAnswer: [
        {
          question: "Who is a good candidate for a hair transplant?",
          answer:
            "Good candidates for a hair transplant include individuals with male or female pattern baldness and those who have lost hair due to injury or scarring. Ideal candidates should have healthy hair follicles in the donor area and realistic expectations for the procedure's outcome.",
        },
        {
          question: "What is the hair transplant procedure like?",
          answer:
            "A healthcare professional removes hair follicles from the donor area during a hair transplant using a surgical technique, such as follicular unit transplantation (FUT) or follicular unit extraction (FUE). The transplanted hair follicles are then implanted into the balding or thinning areas of the scalp.",
        },
        {
          question: "Is hair transplant surgery painful?",
          answer:
            "Hair transplant surgery is typically performed under local anaesthesia, so the procedure is not painful. However, some discomfort may be experienced during the recovery period.",
        },
        {
          question:
            "How long does it take to recover from hair transplant surgery?",
          answer:
            "Recovery time varies depending on the type of procedure performed and the individual's healing process. Most people can return to work and other activities within a week or two, but it may take several months for the full results of the hair transplant to be visible.",
        },
        {
          question:
            "What are the potential risks and side effects of hair transplant surgery?",
          answer:
            "Hair transplant surgery's potential risks and side effects include infection, bleeding, scarring, and hair follicle shock loss. Discussing these risks with a healthcare professional before undergoing the procedure is essential.",
        },
        {
          question: "How effective is hair transplant surgery?",
          answer:
            "Hair transplant surgery can be very effective in improving the appearance of hair loss or thinning. However, the procedure's success depends on several factors, including the individual's age, the extent of hair loss, and the quality of the donor hair follicles. Discussing the expected results of hair transplant surgery with a healthcare professional before undergoing the procedure is essential.",
        },
      ],
    },
    {
      id: "skin",
      name: "Tattoo Removal",
      link: "tattoo-removal",
      ba_image:[twenty],
      description:
        "Tattoo removal treatment is a medical procedure that uses lasers to break down the ink particles in a tattoo, allowing the body's immune system to gradually remove the tattoo from the skin. The lasers used in tattoo removal treatment emit high-intensity light that penetrates the skin and is absorbed by the ink particles in the tattoo.",
      quesAndAnswer: [
        {
          question: "How does tattoo removal treatment work?",
          answer:
            "Tattoo removal treatment uses lasers to break down the ink particles in a tattoo, allowing the body's immune system to remove the tattoo from the skin gradually. The lasers used in tattoo removal emit high-intensity light that penetrates the skin and is absorbed by the ink particles in the tattoo.",
        },
        {
          question: "Is tattoo removal treatment painful?",
          answer:
            "Tattoo removal treatment can be uncomfortable, and many people describe the sensation as similar to being snapped with a rubber band. However, most healthcare professionals offer local anaesthesia or numbing cream to minimize discomfort during the procedure.",
        },
        {
          question:
            "How many sessions are required for complete tattoo removal?",
          answer:
            "The number of sessions required for complete tattoo removal depends on several factors, including the tattoo's size and colour, the ink's depth, and the individual's skin type. Generally, it takes multiple sessions to achieve complete removal, with each session typically spaced several weeks apart.",
        },
        {
          question:
            "Are there any side effects associated with tattoo removal treatment?",
          answer:
            "Potential risks associated with tattoo removal treatment include scarring, infection, and changes in skin pigmentation. However, these risks are minimized when the treatment is performed by a licensed and experienced healthcare professional.",
        },
        {
          question:
            "What is the recovery process like after tattoo removal treatment?",
          answer:
            "After tattoo removal, the treated area may be red, swollen, and tender for several days. Following the healthcare professional's aftercare instructions, including keeping the area clean and dry and avoiding sun exposure, is essential.",
        },
        {
          question: "Can all types of tattoos be removed with laser treatment?",
          answer:
            "Most tattoos can be removed with laser treatment, but specific colours, such as green and yellow, are more difficult to remove than others. Discussing the expected results of tattoo removal treatment with a healthcare professional before the procedure is essential.",
        },
      ],
    },
    {
      id: "skin",
      name: "IV Drip Therapy",
      link: "iv-drip-therapy",
      ba_image:[nineteen],
      description:
        "IV drip therapy, also known as intravenous therapy, is a medical treatment that delivers fluids, vitamins, minerals, and other nutrients directly into the bloodstream through an IV drip. The IV drip contains a mixture of fluids and nutrients tailored to the individual's specific needs, which can vary depending on the reason for treatment.It is also commonly used as a wellness treatment to promote overall health and wellbeing.",
      quesAndAnswer: [
        {
          question: "What is the purpose of IV drip therapy?",
          answer:
            "IV drip therapy aims to deliver fluids, vitamins, minerals, and other nutrients directly into the bloodstream to treat various conditions, including dehydration, nutrient deficiencies, and chronic fatigue syndrome. It is also commonly used as a wellness treatment to promote overall health and well-being.",
        },
        {
          question: "What are the benefits of IV drip therapy?",
          answer:
            "The benefits of IV drip therapy include:Improved hydration, Increased energy levels, Enhanced immune function, Improved skin complexion, Reduced symptoms of various health conditions.",
        },
        {
          question: "What does the IV drip contain?",
          answer:
            "The IV drip contains a mixture of fluids and nutrients tailored to the individual's specific needs, which can vary depending on the reason for treatment. The mixture typically includes a combination of electrolytes, vitamins, minerals, and other nutrients.",
        },
        {
          question: "How long does IV drip therapy take?",
          answer:
            "The length of IV drip therapy can vary depending on the type of treatment and the individual's specific needs. It can take anywhere from 30 minutes to several hours to complete.",
        },
        {
          question: "Can anyone undergo IV drip therapy?",
          answer:
            "Most people can undergo IV drip therapy, but it may not suit individuals with certain medical conditions, such as kidney disease or heart failure. It's important to discuss any underlying medical conditions with a healthcare professional before undergoing the treatment.",
        },
      ],
    },
    {
      id: "skin",
      name: "Laser Hair Reduction",
      link: "laser-hair-reduction",
      ba_image: [eighteen],
      description:
        "Laser hair reduction, also known as laser hair removal, is a cosmetic procedure that uses a laser to remove unwanted hair from various parts of the body. The laser emits a concentrated beam of light that targets the pigment (melanin) in the hair follicle. The laser energy is absorbed by the melanin, which damages the hair follicle and inhibits future hair growth.",
      quesAndAnswer: [
        {
          question: "Is laser hair reduction painful?",
          answer:
            "The level of pain or discomfort during laser hair reduction varies depending on the location of the treatment area and the individual's pain tolerance. Most people describe the sensation as a mild stinging or snapping, similar to a rubber band snapping against the skin. However, the discomfort is usually minimal and can be managed with cooling methods such as a cooling gel or ice pack.",
        },
        {
          question: "How many sessions are required for laser hair reduction?",
          answer:
            "The number of sessions required for laser hair reduction depends on various factors, including the individual's hair type, skin color, and the location of the treatment area. Most people require 6-8 treatments several weeks apart to achieve optimal results. Maintenance sessions may be required to maintain the results.",
        },
        {
          question: "Is laser hair reduction safe?",
          answer:
            "Laser hair reduction is generally considered safe by a licensed and experienced professional. However, there are potential risks associated with the procedure, including skin irritation, redness, and blistering. It's important to discuss any potential risks and complications with a healthcare professional before undergoing the treatment.",
        },
        {
          question: "How long do the results of laser hair reduction last?",
          answer:
            "Laser hair reduction results are typically long-lasting, with most people experiencing a significant reduction in hair growth for several months or even years. However, it's important to note that laser hair reduction is not a permanent hair removal solution, and hair growth may eventually return. Maintenance sessions may be required to maintain the results.",
        },
        {
          question: "Is laser hair reduction suitable for all skin types?",
          answer:
            "Laser hair reduction suits most skin types and colors but may be less effective on lighter hair colors such as blonde or gray. It's important to discuss your skin type and hair color with a healthcare professional before undergoing the treatment to determine if laser hair reduction is suitable for you.",
        },
      ],
    },
    {
      id: "skin",
      name: "Skin Boosters",
      link: "skin-boosters",
      ba_image: [six],
      description:
        "Skin boosters are a type of injectable treatment that uses hyaluronic acid (HA) to improve the skin's hydration, firmness, and elasticity. Hyaluronic acid is a natural substance found in the body that helps to maintain skin hydration and elasticity.Overall, skin boosters are a minimally invasive, safe, and effective way to improve skin hydration, firmness, and elasticity, leading to a more youthful and radiant appearance.",
      quesAndAnswer: [
        {
          question: "Who is a good candidate for skin boosters?",
          answer:
            "Skin boosters are suitable for most people looking to improve their skin's hydration, firmness, and overall appearance. They are particularly beneficial for people with dry, dehydrated skin or those looking to address fine lines, wrinkles, or uneven texture. It's important to discuss your concerns and skin goals with your doctor or skincare professional to determine if skin boosters are suitable for you.",
        },
        {
          question: "How long do skin booster results last?",
          answer:
            "The duration of skin booster results varies depending on the individual and the treatment area. Results can last anywhere from 6-12 months, but undergoing a series of treatments is recommended for optimal and longer-lasting results.",
        },
        {
          question: "Are skin boosters painful?",
          answer:
            "Skin booster injections are typically well-tolerated and are not considered to be very painful. The procedure uses a fine needle; some people may experience mild discomfort or a slight stinging sensation during the injection. Your doctor or skincare professional can apply a topical numbing cream before the treatment to reduce discomfort.",
        },
        {
          question: "How long does a skin booster treatment take?",
          answer:
            "A skin booster treatment typically takes around 30 minutes to complete, although this may vary depending on the treatment area and the number of injections required.",
        },
        {
          question: "Are there any side effects of skin booster treatments?",
          answer:
            "Like any medical procedure or cosmetic treatment, skin boosters risk side effects. Common side effects include mild swelling, bruising, redness, and tenderness at the injection site. These side effects are typically temporary and resolve within a few days. More severe side effects are rare, including infection, allergic reactions, or the formation of lumps or nodules at the injection site. It's important to discuss the potential risks and benefits of skin booster treatments with your doctor or skincare professional before proceeding.",
        },
      ],
    },
    {
      id: "skin",
      name: "Mole & Wart Removal",
      link: "mole-wart-removal",
      ba_image:[twenty_six],
      description:
        "Mole and wart removal is a procedure that involves the removal of moles or warts from the skin. Moles and warts are common skin growths that can occur anywhere on the body and range in size and shape. The most common method of removing moles and warts is by excision, which involves cutting or shaving the growth off the skin with a surgical blade. It is a safe and effective procedure that can improve the appearance of the skin.",
      quesAndAnswer: [
        {
          question: "Are mole and wart removal painful?",
          answer:
            "The level of discomfort during mole and wart removal varies depending on the size and location of the growth, as well as the method of removal. Most procedures involve discomfort, which can be minimized by using local anaesthesia. The pain is typically mild and temporary.",
        },
        {
          question:
            "How long does it take to recover from mole and wart removal?",
          answer:
            "Recovery time depends on the size and location of the growth and the method of removal. Most people can return to their normal activities immediately after the procedure. However, the area may have mild pain, swelling, or redness for a few days. The wound typically heals completely within 1-2 weeks.",
        },
        {
          question: "Does insurance cover mole and wart removal?",
          answer:
            "Mole and wart removal may be covered by insurance if a dermatologist or healthcare professional deems the growth medically necessary. Cosmetic removal for aesthetic reasons may not be covered by insurance.",
        },
        {
          question: "Will there be scarring after mole and wart removal?",
          answer:
            "Scarring is a possible side effect of mole and wart removal, particularly with the excision method. However, the extent of scarring can vary and can be minimized by proper wound care and following the dermatologist or healthcare professional's post-treatment instructions.",
        },
        {
          question:
            "Are there any risks or complications associated with mole and wart removal?",
          answer:
            "As with any medical procedure, there is a risk of infection, bleeding, scarring, or other complications. It's important to discuss the potential risks and benefits of mole and wart removal with a dermatologist or healthcare professional before proceeding.",
        },
      ],
    },
    {
      id: "skin",
      name: "Anti Ageing Treatment",
      link: "anti-ageing-treatment",
      ba_image: [seventeen],
      description:
        "Anti-ageing treatments are medical procedures and cosmetic treatments aimed at reducing the signs of ageing and restoring a youthful appearance to the skin. As we age, our skin loses elasticity, collagen, and moisture, leading to wrinkles, fine lines, age spots, and other signs of ageing. There are many different anti-aging treatments available - Anti ageing treatment, fine lines and wrinkles treatment, Dermal fillers, Chemical peels, Laser resurfacing etc.",
      quesAndAnswer: [
        {
          question: "Are anti-ageing treatments safe?",
          answer:
            "Most anti-ageing treatments are safe when performed by a qualified and experienced professional. However, all medical procedures and cosmetic treatments carry some risks. Discuss any treatment's potential risks and benefits with your doctor or skincare professional before proceeding.",
        },
        {
          question: "How long do anti-ageing treatments last?",
          answer:
            "The duration of anti-ageing treatments varies depending on the treatment type and the individual's skin condition. Some treatments like Anti ageing treatment, fine lines, wrinkles treatment and dermal fillers, are temporary and may need to be repeated every few months to maintain results. Other treatments, like laser resurfacing, can have long-lasting results.",
        },
        {
          question: "Are there any side effects of anti-ageing treatments?",
          answer:
            "All anti-ageing treatments have the potential to cause side effects, which can range from mild to severe. Common side effects include redness, swelling, bruising, and discomfort at the treatment site. More severe side effects are rare but can include infection, scarring, and allergic reactions.",
        },
        {
          question: "When should I start anti-ageing treatments?",
          answer:
            "There is no specific age at which people should start using anti-ageing treatments, as everyone's skin ages differently. However, it's generally recommended to start focusing on skin health and preventing damage early on, such as using sun protection and avoiding harmful environmental factors.",
        },
        {
          question:
            "Are there any natural or at-home anti-ageing treatments that work?",
          answer:
            "Natural and at-home remedies may help reduce the appearance of ageing skin, such as using topical antioxidants, moisturizing regularly, and getting enough sleep. However, the effectiveness of these remedies can vary widely, and professional anti-ageing treatments are typically more effective at achieving noticeable results.",
        },
      ],
    },
    {
      id: "skin",
      name: "Hydrafacial Treatment",
      link: "hydrafacial-treatment",
      ba_image: [two],
      description:
        "Hydrafacial is a non-invasive, multi-step facial treatment that uses patented technology to cleanse, exfoliate, extract, and hydrate the skin. Hydrafacial is a customizable treatment that can address various skin concerns, including fine lines and wrinkles, hyperpigmentation, oily and congested skin, and uneven skin tone. It is suitable for all skin types, including sensitive skin.",
      quesAndAnswer: [
        {
          question: "Is hydra facial safe for all skin types?",
          answer:
            "Yes, hydra facial is safe for all skin types, including sensitive skin. The treatment is gentle and non-invasive and can be customized to address specific skin concerns.",
        },
        {
          question: "How long does a hydra facial treatment take?",
          answer:
            "A hydrafacial treatment typically takes 30 to 45 minutes, depending on the customized options and the individual's skin condition.",
        },
        {
          question: "Is there any downtime after a hydrafacial treatment?",
          answer:
            "No, there is no downtime after a hydrafacial treatment. Most people are able to resume their normal activities immediately after the treatment.",
        },
        {
          question: "How often can I get a hydrafacial treatment?",
          answer:
            "The frequency of hydrafacial treatments depends on the individual's skin condition and goals. For best results, it is recommended to receive a hydrafacial treatment once a month.",
        },
        {
          question: "What are the benefits of hydrafacial treatment?",
          answer:
            "Hydrafacial treatment has several benefits, including improved skin texture, tone, and hydration. It can also reduce the appearance of fine lines and wrinkles, minimize pore size, and improve the skin's overall appearance. The treatment is gentle and non-invasive, making it a popular choice for people who want to improve the appearance of their skin without undergoing a more invasive procedure.",
        },
      ],
    },
    {
      id: "skin",
      name: "Q-Switch/Carbon Facial",
      link: "qswitch-carbon-facial",
      ba_image: [twenty_two],
      description:
        "Q-Switch/Carbon Facial is a non-invasive laser skin treatment that uses a Q-switched laser and a carbon-based lotion to improve the appearance of the skin. It is sometimes referred to as a black doll or China doll facial due to the carbon mask used during the procedure.",
      quesAndAnswer: [
        {
          question: "How does the Q-switch/carbon facial work?",
          answer:
            "During the treatment, a carbon-based lotion is applied to the face, which penetrates the pores and is absorbed by the skin. The Q-switched laser is then used to heat the carbon particles, causing them to vaporize and remove impurities from the skin. The laser also stimulates collagen production, improving skin texture and tone.",
        },
        {
          question: "What are the benefits of a Q-switch/carbon facial? ",
          answer:
            "The treatment is relatively quick, usually taking around 30 minutes to complete, and is painless with no downtime. It is commonly used to improve the appearance of fine lines and wrinkles, uneven skin tone, and acne scars. It can also help to shrink pores, reduce oil production, and improve skin texture.",
        },
        {
          question: "How many treatments are required?",
          answer:
            "The number of treatments required can vary depending on the individual and the specific skin concerns being addressed. A series of treatments spaced several weeks apart may be needed to achieve the best results.",
        },
        {
          question: "Is there any downtime after the treatment?",
          answer:
            "There is generally no downtime after a Q-switch/carbon facial; most people can return to normal activities immediately after the treatment. Some redness and mild swelling may be experienced, but this usually subsides within a few hours. It's essential to avoid sun exposure and use sunscreen after the treatment.",
        },
        {
          question: "Is the treatment safe?",
          answer:
            "The Q-switch/carbon facial is generally considered safe for most people, but it may not suit everyone. Discussing your medical history with your healthcare provider before this treatment is essential.",
        },
      ],
    },
    {
      id: "hair",
      name: "Hairfall Treatment",
      link: "hairfall-treatment",
      ba_image: [twenty_three],
      description:
        "Hair fall treatment refers to the various methods and techniques used to address hair loss and promote hair growth. Hair fall or hair loss can occur due to a variety of reasons such as genetics, hormonal changes, aging, nutritional deficiencies, stress, and certain medical conditions.",
      quesAndAnswer: [
        {
          question: "What are the factors that cause hair fall?",
          answer:
            "Hair fall can result from several factors, such as genetics, hormonal fluctuations, aging, poor nutrition, stress, and certain medical conditions.",
        },
        {
          question: "What measures can I take to prevent hair fall?",
          answer:
            "Maintaining a healthy diet, avoiding harsh hair treatments, managing stress, and using mild hair care products are some of the ways to prevent or reduce hair fall.",
        },
        {
          question:
            "What are the most commonly used methods for treating hair fall?",
          answer:
            "Topical treatments, oral medications, platelet-rich plasma (PRP) therapy, and hair transplant surgery are some of the commonly used methods for treating hair fall.",
        },
        {
          question: "Is hair fall treatment safe?",
          answer:
            "Most hair fall treatments are safe when administered by a qualified professional. However, some treatments may have side effects, such as scalp irritation or hair thinning.",
        },
        {
          question:
            "How long does it take to observe the results of hair fall treatment?",
          answer:
            "The time taken to observe results from hair fall treatment varies depending on the severity of hair loss and the treatment method used. Some treatments may show results within a few weeks, while others may take several months.",
        },
        {
          question: "How frequently should I undergo hair fall treatment?",
          answer:
            "The frequency of hair fall treatment depends on the type of treatment and the individual's hair condition. Some treatments may require multiple sessions spaced out over several weeks or months.",
        },
        {
          question: "Is hair fall treatment a permanent solution?",
          answer:
            "Hair fall treatment results may last for a long time, but they may not be permanent. Hair fall may recur over time, especially if the underlying cause is not addressed.",
        },
      ],
    },
    {
      id: "skin",
      name: "Pigmentation Treatment",
      link: "pigmentation-treatment",
      ba_image: [thirteen],
      description:
        "Pigmentation refers \n to the natural color of the skin, which is determined by the amount and type of melanin produced by melanocytes, the specialized cells that produce melanin. Pigmentation disorders can occur when there is an overproduction or underproduction of melanin, leading to uneven skin tone or discoloration.",
      quesAndAnswer: [
        {
          question: "What causes pigmentation on the skin?",
          answer:
            "Pigmentation can be caused by a variety of factors such as sun exposure, hormonal changes, genetics, aging, and certain medical conditions.",
        },
        {
          question: "Can pigmentation be prevented?",
          answer:
            "Pigmentation can be prevented or minimized by wearing sunscreen, avoiding excessive sun exposure, and maintaining a healthy lifestyle.",
        },
        {
          question: "What are the most common types of pigmentation treatment?",
          answer:
            "The most common types of pigmentation treatment include topical treatments such as hydroquinone and retinoids, chemical peels, laser therapy, and microdermabrasion.",
        },
        {
          question: "Are pigmentation treatments safe?",
          answer:
            "Most pigmentation treatments are safe when performed by a qualified professional. However, some treatments may have side effects such as redness, swelling, or skin irritation.",
        },
        {
          question:
            "How long does it take to see results from pigmentation treatment?",
          answer:
            "The time it takes to see results from pigmentation treatment varies depending on the type of treatment and the severity of the pigmentation. Some treatments may show results within a few weeks, while others may take several months.",
        },
        {
          question: "How often do I need to undergo pigmentation treatment?",
          answer:
            "The frequency of pigmentation treatment depends on the type of treatment and the individual's skin condition. Some treatments may require multiple sessions spaced out over several weeks or months.",
        },
        {
          question: "Is pigmentation treatment permanent?",
          answer:
            "The results of pigmentation treatment may be long-lasting, but they may not be permanent. Pigmentation may recur over time, especially if the underlying cause is not addressed.",
        },
      ],
    },
  ];
  return (
    <>
      <Box sx={{}}>
        {ServiceData.map((item, index) => {
          return (
            <>
              {item.link === name && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      height: { md: "400px", xs: "200px" },
                      background: `url(${skinPic}) center center no-repeat`,
                      backgroundSize: "cover",
                      boxSizing: "border-box",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { md: "flex-start", xs: "flex-end" },
                      justifyContent: { md: "center", xs: "" },
                      fontFamily: "'Didact Gothic',sans-serif !important",
                      gap: "20px",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "rgba(55,69,73,.9)",
                        width: { md: "30%", xs: "90%" },
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        p: 2,
                        ml: { md: 8, xs: 0 },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: { md: "24px", xs: "18px" },
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Divider
                        sx={{
                          width: "20%",
                          border: "1px solid #fff",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: { md: "20px", xs: "16px" },
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: {xs:"column",md:"row"},
                      marginTop: {xs:"150px",md:"30px"},
                      gap: "20px",
                    }}
                  >
                    {item.ba_image &&
                      item.ba_image.map((image, index) => {
                        return (
                          <img
                            src={image}
                            width={350}
                            style={{ borderRadius: "8px" }}
                            alt="acne scar treatment by dr. aishwariya bhatia"
                          />
                        );
                      })}
                    {/* <div style={{ display: "flex", gap: "120px" }}>
                      <h1
                        style={{
                          margin: "0px",
                          fontWeight: "600",
                          fontSize: "28px",
                        }}
                      >
                        BEFORE
                      </h1>
                      <h1
                        style={{
                          margin: "0px",
                          fontWeight: "600",
                          fontSize: "28px",
                        }}
                      >
                        AFTER
                      </h1>
                    </div> */}
                  </Box>
                  <Box sx={{ display: "flex", my: { xs: 4, md: 10 } }}>
                    <Button
                      component={Link}
                      to="/appointment"
                      variant="outlined"
                      sx={{
                        color: "white",
                        margin: "0 auto",
                        width: { md: "28%", xs: "90%" },
                        padding:"6px",
                        backgroundColor: "#374549",
                        border: "none",
                        fontSize: { xs: 18, md: 18 },
                        "&:hover": {
                          backgroundColor: "#cce0e5",
                          color: "black",
                          border: "none",
                        },
                      }}
                    >
                      Make a Reservation now
                    </Button>
                  </Box>
                  <Box sx={{ my: { xs: 8, md: 10 } }}>
                    <>
                      <h2
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          fontSize:"23px"
                        }}
                      >
                        FREQUENTLY ASKED QUESTIONS (FAQ's)
                      </h2>
                      {item.quesAndAnswer.map((qa, index) => {
                        return (
                          <>
                            <Box
                              sx={{
                                width: { md: "50%", xs: "90%" },
                                margin: "0 auto",
                                py: {md:5,xs:3},
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  fontWeight: "500",
                                }}
                              >
                                {qa.question}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "18px",
                                  fontFamily:
                                    "'Didact Gothic',sans-serif !important",
                                  color: "#333333",
                                  pt: 1,
                                }}
                              >
                                {qa.answer}
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                    </>
                  </Box>
                </>
              )}
            </>
          );
        })}
        <AppointmentBlock />
      </Box>
    </>
  );
}

export default Services;
