import React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import docPic from "../photos/docPic.jpeg";

function OurTeam() {
  return (
    <Box sx={{ my: 3, zIndex: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 3,mt:5 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: { md: "22px", xs: "20px" },
            letterSpacing: "3px",
            fontWeight: 600,
          }}
        >
          OUR TEAM
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "20px auto",
            border: "0.8px solid black",
            height: "40px",
          }}
        />
      </Box>
      <Box>
        <Box
          sx={{
            width: { md: "80%", xs: "95%" },
            margin: "0px auto",
            py: { md: 2, xs: 0 },
            display: "flex",
            px: { md: 2, xs: 1 },
            position: "relative",
            flexDirection: { xs: "column-reverse", md: "row" },
            borderRadius: "5px",
            boxShadow: {
              md: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
              xs: "",
            },
          }}
        >
          <Box
            sx={{
              width: { md: "60%", xs: "100%" },
              p: 1,
              boxSizing: "border-box",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "30px", xs: "27px" },
                width: {md:"95%",xs:"100%"},

                fontWeight: "bold",
              }}
            >
              Dr. Aishwarya Bhatia
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "17px", md: "18px" },
                width: {md:"95%",xs:"100%"},
                lineHeight: { md: "25px", xs: "24px" },
                mt: 2,
                fontFamily: "'Didact Gothic', sans-serif",

                letterSpacing: "0.9px",
              }}
            >
              Dr. Aishwarya Bhatia, owner of Dune Aesthetics, is an accomplished
              and experienced medical professional. She completed her MBBS
              degree from Dr. D.Y. Patil Medical College in Mumbai and pursued
              international fellowships and diploma programs in facial
              aesthetics to expand her expertise. Driven by a vision of
              personalized care, she tailors treatments to meet each patient's
              unique needs and goals. With a focus on enhancing natural beauty
              and boosting self-confidence, Dr. Aishwarya has successfully
              treated a diverse range of patients, including notable celebrities
              from the media, sports, and entertainment industries in Bombay and
              Delhi. Her comprehensive services include facial rejuvenation,
              injectables, dermal fillers, and more. Patients can trust in her
              professionalism, skill, and dedication to achieving optimal
              outcomes. Dune Aesthetics offers a welcoming and comfortable
              environment where patients feel at ease, knowing they are in the
              hands of a compassionate and highly competent doctor. By choosing
              Dr. Aishwarya Bhatia and Dune Aesthetics, patients embark on a
              personalized treatment journey, with exceptional results that
              align with their unique aesthetic goals.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { md: "40%", xs: "100%" },
              minHeight: "300px",
              margin:"0 auto",
              position: { md: "relative", xs: "" },
              top: "-70px",
              right: "20px",
              display: { xs: "flex", md: "block" },
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AnimatePresence>
              <motion.div
                style={{ position: "absolute" }}
                initial={{ y: 100, opacity: 0 }}
                whileInView="visible"
                viewport={{ once: true }}
                variants={{
                  visible: { y: 0, opacity: 1 },
                  hidden: { y: -100, opacity: 0 },
                }}
                transition={{ duration: 0.8 }}
              >
                <img
                  className="imageForMobile"
                  src={docPic}
                  width={400}
                  height={500}
                  alt="Dr. Aishwarya Bhatia - SKIN SPECIALIST"
                ></img>
              </motion.div>
            </AnimatePresence>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default OurTeam;
